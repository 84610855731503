
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-row>
          <el-col :span="12">
            <el-form-item label="单位" prop="companyId">
              <!-- <el-select
                v-model="formModel.companyId"
                filterable
                placeholder="请选择"
                style="width:240px"
              >
                <el-option
                  v-for="company in companyResult"
                  :key="company.id"
                  :label="company.name"
                  :value="company.id"
                ></el-option>
              </el-select>-->
              <el-select-tree
                :props="props"
                :options="companyResult"
                v-model="formModel.companyId"
                height="200"
              ></el-select-tree>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现有人员查询">
              <el-select
                v-model="selectedPerson"
                filterable
                remote
                placeholder="请输入人员姓名"
                :remote-method="queryPersonList"
                @change="selectPerson"
                :loading="loading"
              >
                <el-option
                  v-for="person in personList"
                  :key="person.id"
                  :label="person.name + '-' + person.phone"
                  :value="person"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="formModel.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="formModel.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="openid" prop="name">
              <el-input v-model="formModel.openId" placeholder="请输入openid"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" v-model="formModel.remark" :rows="2" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="预警温度" prop="temperature">
              <el-input v-model="formModel.temperature" placeholder="请输入预警温度"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报警触发次数" prop="times">
              <el-input v-model="formModel.times" placeholder="请输入报警触发次数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="未测温提醒" prop="missingNoticeEnabled">
              <el-radio v-model="formModel.missingNoticeEnabled" :label="true">开</el-radio>
              <el-radio v-model="formModel.missingNoticeEnabled" :label="false">关</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import warningPusherApi from "@/api/base/warningPusher";
import personInfoApi from "@/api/base/personInfo";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title", "companyResult"],
  data() {
    let checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
        console.log(reg.test(value));
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      formModel: {},
      ruleValidate: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        companyId: [
          {
            required: true,
            message: "单位不能为空",
            trigger: "change"
          }
        ],
        phone: [
          { required: true, message: "手机不能为空", trigger: "blur" },
          {
            validator: checkPhone,
            trigger: "blur"
          }
        ],
        temperature: [
          { required: true, message: "预警度数不能为空", trigger: "blur" },
          {
            type: "number",
            message: "请输入数字格式",
            trigger: "blur",
            transform(value) {
              return Number(value);
            }
          }
        ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      canQuery: true,
      personList: [],
      selectedPerson: {},
      tableHeight: 400,
      qrcodeImg: "",
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    this.loadTree();
  },
  methods: {
    getSelectedValue(value) {
      this.formModel.companyId = value;
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;
            if (self.formModel.companyId == null) {
              self.formModel.companyId = "";
            }

            if (id == null || id.length == 0) {
              return warningPusherApi.add(self.formModel);
            } else {
              return warningPusherApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
    queryPersonList(query) {
      var formData = new FormData();
      formData.append("companyId", this.formModel.companyId);
      formData.append("name", query);

      formData.append("pageIndex", 1);
      formData.append("pageSize", 20);

      personInfoApi.pageList(formData).then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          this.personList = jsonData.data.data;
        }
      });
    },
    selectPerson() {
      this.formModel.name = this.selectedPerson.name;
      this.formModel.phone = this.selectedPerson.phone;
      this.formModel.openId = this.selectedPerson.openId;
    }
  },
  mounted: function() {
    var self = this;
    (function() {
      if (self.businessKey.length == 0) {
        return warningPusherApi.create();
      } else {
        return warningPusherApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>