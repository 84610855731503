<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/warningPusher">预警上报人员管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="所属单位" prop="companyId">
        <!-- <el-select
          v-model="queryModel.companyId"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:220px;"
        >
          <el-option
            v-for="company in companyResult"
            :key="company.id"
            :label="company.name"
            :value="company.id"
          ></el-option>
        </el-select>-->
        <el-select-tree
          :props="props"
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input type="text" size="mini" v-model="queryModel.phone"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      :height="tableHeight"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="姓名" width="100" fixed="left"></el-table-column>
      <el-table-column prop="remark" label="备注" width="150"></el-table-column>
      <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
      <el-table-column prop="companyName" label="单位" width="120"></el-table-column>
      <el-table-column prop="temperature" label="预警触发温度" width="120"></el-table-column>
      <el-table-column prop="times" label="上报触发次数" width="120"></el-table-column>
      <el-table-column prop="openId" label="是否绑定公众号" width="120">
        <template slot-scope="{row}">{{row.openId == null ? "否" : "是"}}</template>
      </el-table-column>
      <el-table-column prop="missingNoticeEnabled" label="未测温提醒" width="120">
        <template slot-scope="{row}">
          <el-switch v-model="row.missingNoticeEnabled" @change="changeMissingNoticeEnabled(row)" />
        </template>
      </el-table-column>
      <el-table-column prop="openId" label="open_id" width="280"></el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="{row}">
          <el-row>
            <el-col>
              <el-link type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>-
              <el-link type="danger" :underline="false" @click="handleDelete(row)">删除</el-link>-
              <el-popover
                placement="right"
                width="400"
                trigger="click"
                @show="handleBindWeChat(row)"
                @hide="handleHideWechat(row)"
              >
                <el-image style="width: 400px; height: 400px" :src="qrcodeImg">
                  <div slot="error" class="image-slot">
                    加载中
                    <span class="dot">...</span>
                  </div>
                </el-image>
                <el-link type="primary" :underline="false" slot="reference">绑定公众号</el-link>
              </el-popover>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <warningPusher-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :typeData="typeData"
      :companyResult="companyResult"
      @close="onDetailModalClose"
    ></warningPusher-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import WarningPusherDetail from "./warningPusher-detail";
import warningPusherApi from "@/api/base/warningPusher";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import wxApi from "@/api/base/wx";
import pageUtil from "@/utils/page";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseWarningPusherList",
  data() {
    var self = this;

    return {
      queryModel: {
        phone: "",
        name: "",
        companyId: ""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      typeData: [],
      companyResult: [],
      tableHeight: 400,
      qrcodeImg: "",
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    this.loadTree();
  },
  methods: {
    getSelectedValue(value) {
      this.queryModel.companyId = value;
    },
    loadTree() {
      var formData = new FormData();

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      console.log(tree);

      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then(resp => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("name", self.queryModel.name);
      formData.append("phone", self.queryModel.phone);
      if (self.queryModel.companyId == null) {
        self.queryModel.companyId = "";
      }
      formData.append("companyId", self.queryModel.companyId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      warningPusherApi
        .pageList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          warningPusherApi.remove(record.id).then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        warningPusherApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleBindWeChat(record) {
      //二维码地址
      //5000,id
      //wechat/createQrcode
      var self = this;
      var formData = new FormData();
      formData.append("scene_str", "5000," + record.id);
      wxApi.createQrcode(formData).then(function(response) {
        var jsonData = response.data;
        if (jsonData.result) {
          self.qrcodeImg = jsonData.data;
        }
      });
    },
    handleHideWechat(record) {
      var self = this;
      self.qrcodeImg = "";
    },
    changeMissingNoticeEnabled(row) {
      warningPusherApi.update(row);
    }
  },
  mounted: function() {
    var self = this;
    this.changePage(1);

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    setTimeout(()=>{
      this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el);
    },1000);
  },
  components: {
    "warningPusher-detail": WarningPusherDetail,
    "el-select-tree": SelectTree
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>