import request from '@/utils/request'
import constant from '@/constant'
import { getToken } from "@/utils/auth"; // get token from cookie

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/personInfo/pageList", formData);
}
function ownerList(formData) {
  return request.post(constant.serverUrl + "/base/personPopedom/ownerList", formData);
}
function create() {
  return request.get(constant.serverUrl + "/base/personInfo/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/personInfo/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/personInfo/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/personInfo/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/personInfo/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/personInfo/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/base/personInfo/exportXls", formData);
  //window.open(constant.serverUrl + "/base/personInfo/exportXls?token=" + getToken());
}

function enabledFace(formData) {
  //人脸授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledFace", formData);
}

function enabledFaceList(ids) {
  //批量人脸授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledFaceList", ids, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function enabledCard(formData) {
  //刷卡授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledCard", formData);
}

function enabledApp(formData) {
  //手机授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledApp", formData);
}

function enabledWechatNotice(formData) {
  //接收微信通知
  return request.post(constant.serverUrl + "/base/personInfo/enabledWechatNotice", formData);
}

function enabledGuest(formData) {
  //访客授权
  return request.post(constant.serverUrl + "/base/personInfo/enabledGuest", formData);
}

function dataSync(idList) {
  //数据同步
  return request.post(constant.serverUrl + "/base/personInfo/dataSync", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function unbindWechat(id) {
  return request.post(constant.serverUrl + "/base/personInfo/unbindWechat", id, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function lifeRecordList(formData) {
  return request.post(constant.serverUrl + "/base/personInfo/lifeRecordList", formData);
}

function clearFaceImg(id) {
  return request.post(constant.serverUrl + "/base/personInfo/clearFaceImg", id, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function batchClearFaceImg(idList) {
  return request.post(constant.serverUrl + "/base/personInfo/batchClearFaceImg", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}


function enabledSync(formData) {
  //开启自动同步
  return request.post(constant.serverUrl + "/base/personInfo/enabledSync", formData);
}

function enabledSyncList(ids) {
  //开启自动同步
  return request.post(constant.serverUrl + "/base/personInfo/enabledSyncList", ids, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function bindWechat(userId) {
  var formData = new FormData();

  formData.append("expire_seconds", "3600");
  formData.append("action_name", "QR_STR_SCENE");
  formData.append("scene_str", process.env.VUE_APP_BIND_PERSON_WECHAT_CODE + "," + userId);

  return request.post(constant.serverUrl + "/wechat/qrcode/create", formData);
}

function findByCompanyId(formData) {
  return request.post(constant.serverUrl + "/base/personInfo/findByCompanyId", formData);
}

function findByCompanyCode() {
  return request.post(constant.serverUrl + "/base/personInfo/findByCompanyCode");
}

function updateHealthyCode(personId) {
  return request.get(constant.serverUrl + `/base/personInfo/updateHealthyCode?personId=${personId}`);
}

function deleteHkPerson(id) {
  return request.post(constant.serverUrl + "/base/personInfo/deleteHkPerson/" + id);
}

function queryCompanyPersonNum() {
  return request.post(constant.serverUrl + "/base/personInfo/queryCompanyPersonNum/");
}

function temporaryPageList(formData) {
  return request.post(constant.serverUrl + "/base/personInfo/temporaryPageList", formData);
}

function changeEffectiveDate(formData) {
  return request.post(constant.serverUrl + "/base/personInfo/changeEffectiveDate", formData);
}

function downloadZip(parentId, subordinate) {
  window.open(constant.serverUrl + "/base/personInfo/downloadImgZip?parentId=" + parentId + 
  "&subordinate=" + subordinate + "&token=" + getToken());
}

export default {
  pageList, create, edit, add, update, remove, batchRemove, exportXls,
  enabledFace, enabledCard, enabledApp, enabledGuest, dataSync, enabledFaceList,
  enabledWechatNotice, unbindWechat, lifeRecordList, clearFaceImg, batchClearFaceImg,
  enabledSync, enabledSyncList, bindWechat, findByCompanyId, findByCompanyCode, updateHealthyCode,
  deleteHkPerson, ownerList, queryCompanyPersonNum, temporaryPageList,changeEffectiveDate,downloadZip
}