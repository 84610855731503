import request from '@/utils/request'
import constant from '@/constant'

function createQrcode(formData){
  return request.post(constant.serverUrl + "/wechat/qrcode/create", formData);
}


export default {
  createQrcode
}