function autoAdjustHeight(el) {
    var header = document.querySelector(".el-header");
    var headerHeight = 0;

    if(header!=null){
        headerHeight = header.offsetHeight;
    }
    console.log("headerHeight=" + headerHeight);

    //页面高度-列表上面的高度-header高度-分页组件高度
    return window.innerHeight - el.offsetTop - headerHeight - 40;
}

export default{
    autoAdjustHeight
}